import i18n from '@/lang/i18n'

export default [
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('dashboard'),
    to: '/dashboard',
    icon: 'cil-speedometer'
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('system_management')]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('organization'),
    route: '/organization',
    icon: 'cil-people',
    items: [
      // {
      //   name: i18n.t('companies'),
      //   to: '/organization/company',
      //   icon: 'cil-building',
      //   role: 'root',
      //   badge: {
      //     color: 'secondary',
      //     text: i18n.t('manager')
      //   }
      // },
      // {
      //   name: i18n.t('users'),
      //   to: '/organization/user',
      //   icon: 'cil-user',
      //   role: 'root',
      //   badge: {
      //     color: 'secondary',
      //     text: i18n.t('manager')
      //   }
      // }
    ]
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('website_management')]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('posts_management'),
    route: '/posts',
    icon: 'cil-newspaper',
    items: [
      {
        name: i18n.t('posts'),
        to: '/posts/posts',
        icon: 'cil-newspaper',
        role: 'root'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('products_management'),
    route: '/products',
    icon: 'cil-list',
    items: [
      {
        name: i18n.t('categories'),
        to: '/products/categories',
        icon: 'cil-list',
        role: 'root'
      },
      {
        name: i18n.t('tags'),
        to: '/products/tags',
        icon: 'cil-tags',
        role: 'root'
      },
      {
        name: i18n.t('products'),
        to: '/products/products',
        icon: 'cil-clipboard',
        role: 'root'
      },
      {
        name: i18n.t('serial_numbers'),
        to: '/products/serial_numbers',
        icon: 'cil-list-numbered-rtl',
        role: 'root'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('shipping_management'),
    route: '/shipping',
    icon: 'cil-truck',
    items: [
      {
        name: i18n.t('shipping_methods'),
        to: '/shipping/methods',
        icon: 'cil-truck',
        role: 'root'
      },
      {
        name: i18n.t('shipping_fee_list'),
        to: '/shipping/fees',
        icon: 'cil-dollar',
        role: 'root'
      }
    ]
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('business_management')]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('orders_management'),
    route: '/orders',
    icon: 'cil-cart',
    items: [
      {
        name: i18n.t('orders'),
        to: '/orders/orders',
        icon: 'cil-credit-card',
        role: 'root'
      }
    ]
  },
]
